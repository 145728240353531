import axiosObj from "./axios";
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const Campaign = () => {
    const navigate = useRouter();
    useEffect(() => {
        if (navigate.isReady) {
            const campaignName = navigate.query.utm_campaign || 'su10';
            const medium = navigate.query.utm_medium;
            if (campaignName && medium !== 'email') {
                const showCampaign = async () => {
                    try {
                        const response = await axiosObj.get('/api/email-marketing/campaigns?campaignName=' + campaignName);
                        const campaignData = response.data[0];
                        if (campaignData && campaignData.active) {
                            const script = document.createElement('script');
                            script.async = true;
                            script.src = campaignData.script;
                            script.dataset.form = campaignData.form;
                            document.body.appendChild(script);
                        }
                    } catch (error) {
                        console.error('Error fetching campaign:', error);
                    }
                };
                showCampaign();
            }
        }
    }, [navigate]);

    return null;
};

export default Campaign;
